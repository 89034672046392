import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import config from "../config"
import * as actionTypes from '../store/actions';


export const loginUser = (param)=>(dispatch)=> { 
try{

    axios
        .post(config.backurl+"/api/login", param)
        .then(res => {
            const { token } = res.data;
            sessionStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            console.log("decoded",decoded);
            sessionStorage.setItem("name",decoded.name);
            sessionStorage.setItem("role",decoded.level);
           
            window.location.href= window.location.origin+"/dashboard/default"
        setTimeout(() => {
        }, 5000);
         })
        .catch(err =>{
   

            if(err  &&  err.response &&  err.response.data)
             dispatch(errorCall(err.response.data))
          
            
            }
        );

}
catch(e){
    console.log("sadsadasdddd",e);
}
};
export const errorCall = decoded => {
    return {
        type: actionTypes.GET_ERRORS,
        payload: decoded
    }
}

export const setCurrentUser = decoded => {
    return {
        type: actionTypes.SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: actionTypes.USER_LOADING
    };
};

export const logoutUser = ()=>(dispatch)  => {
    sessionStorage.removeItem("accessToken");
    window.location.href= window.location.origin+"/login"
};
