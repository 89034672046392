export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const USER_LOADING = 'USER_LOADING';

export const USER_ADD = 'USER_ADD';
export const USER_UPDATE = 'USER_UPDATE';

export const ABOUT_ADD ="ABOUT_ADD";
export const ABOUT_UPDATE = "ABOUT_UPDATE";

export const SOCIALLINK_ADD ="SOCIALLINK_ADD";
export const SOCIALLINK_UPDATE = "SOCIALLINK_UPDATE";

export const NEWS_ADD ="NEWS_ADD";
export const NEWS_UPDATE = "NEWS_UPDATE";

export const ADDRESS_ADD ="ADDRESS_ADD";
export const ADDRESS_UPDATE = "ADDRESS_UPDATE";

export const CONTACT_ADD ="CONTACT_ADD";
export const CONTACT_UPDATE = "CONTACT_UPDATE";

export const GLOBAL_ADD ="GLOBAL_ADD";
export const GLOBAL_UPDATE = "GLOBAL_UPDATE";


export const NFT_ADD ="NFT_ADD";
export const NFT_UPDATE = "NFT_UPDATE";

export const SCENATIOS_ADD ="SCENATIOS_ADD";
export const SCENATIOS_UPDATE = "SCENATIOS_UPDATE";

export const BUSINESS_ADD ="BUSINESS_ADD";
export const BUSINESS_UPDATE = "BUSINESS_ADD";

export const PRODUCT_ADD ="PRODUCT_ADD";
export const PRODUCT_UPDATE = "PRODUCT_UPDATE";

export const RD_ADD ="RD_ADD";
export const RD_UPDATE = "RD_UPDATE";

export const WASH_ADD ="WASH_ADD";
export const WASH_UPDATE = "WASH_UPDATE";

export const INTER_ADD ="INTER_ADD";
export const INTER_UPDATE = "INTER_UPDATE";

export const MANU_ADD ="MANU_ADD";
export const MANU_UPDATE = "MANU_UPDATE";

export const PAGEIMAGE_UPDATE = "PAGEIMAGE_UPDATE";

export const VERTICAL_ADD ="VERTICAL_ADD";
export const VERTICAL_UPDATE = "VERTICAL_UPDATE";

export const ETHICAL_UPDATE = "ETHICAL_UPDATE";

export const WORLD_UPDATE = "WORLD_UPDATE";

export const HOME_UPDATE = "HOME_UPDATE";

export const HOMENEWS_ADD = "HOMENEWS_ADD";
export const HOMENEWS_UPDATE = "HOME_UPDATE";

export const OURBUSINESS_ADD ="OURBUSINESS_ADD";
export const OURBUSINESS_UPDATE = "OURBUSINESS_UPDATE";

export const THREE_ADD ="THREE_ADD";
export const THREE_UPDATE = "THREE_UPDATE";

export const ALLDESIGN_ADD ="ALLDESIGN_ADD";
export const ALLDESIGN_UPDATE = "ALLDESIGN_UPDATE";

export const NFTBRAND_ADD ="NFTBRAND_ADD";
export const NFTBRAND_UPDATE = "NFTBRAND_UPDATE";


